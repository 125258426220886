import React, {Component} from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Jobsection from '../Element/Jobsection';
import * as JobSearch from '../../services/JobSearch';
import JobSearchform from '../Element/Jobsearchform';
import { PagedResult } from '../../services/PagedResult';

import './Homepage2.css';

var bnr1 = require('./../../images/main-slider/slide1.jpg');

interface IKeepTheState {
	isLoading: boolean;
	currentModel: PagedResult<JobSearch.IAmJobFound>;

	scrollToJobs?: boolean | null;

	searchText: string;
	language: JobSearch.IAmJobSearchLanguage;
	categories: JobSearch.IAmJobSearchJobCategory[];
	filterCurrency: string;

	pageNumber: number;
	sortByField: JobSearch.IAmJobOrdersSorting;
}

class Homepage2 extends Component<any, IKeepTheState>{
	private readonly pageSize: number = 50;

	public constructor(props: {} | Readonly<{}>) {
		super(props);

		this.state = {
			isLoading: true,
			currentModel: { items: [], pageNumber: 0, pageSize: this.pageSize, totalItemsCount: 0, totalPagesCount: 0 } as PagedResult<JobSearch.IAmJobFound>,
			language: JobSearch.IAmJobSearchLanguage.Unknown, 
			searchText: "",
			categories: [],
			filterCurrency: "PLN", //todo: unhardcode this!,
			pageNumber: 1,
			sortByField: JobSearch.IAmJobOrdersSorting.Newest
		};

		this.onJobsRetrieved = this.onJobsRetrieved.bind(this);
		this.onSearchStarted = this.onSearchStarted.bind(this);
	}

	onJobsRetrieved(jobs: PagedResult<JobSearch.IAmJobFound>, filterCurrency: string): void {
		this.setState({currentModel: jobs, filterCurrency: filterCurrency});
	}

	onSearchStarted(): void {
		this.setState({currentModel: { items: [], pageNumber: 0, pageSize: this.pageSize, totalItemsCount: 0, totalPagesCount: 0 } as PagedResult<JobSearch.IAmJobFound>, pageNumber: 1});
	}

	render(){
		return(
			<div className="page-wraper">
				<Header />	

				<div className="page-content">
					<div className="dez-bnr-inr dez-bnr-inr-md overlay-black-dark" style={{backgroundImage:"url(" + bnr1 + ")"}}>
						<div className="container">
							<div className="dez-bnr-inr-entry align-m text-white">
								<div className=" job-search-form">
									<h3 className="text-center"><small>(prawie)</small> Wszystkie oferty w kraju.</h3>
									<JobSearchform onDataRetrieved={this.onJobsRetrieved} onSearchStarted={this.onSearchStarted} naturalPageNumber={this.state.pageNumber} sortByField={this.state.sortByField} />
								</div>
							</div>
						</div>
					</div>
					<Jobsection 
						CurrentModel={this.state.currentModel} 
						MaxJobsPerPage={this.state.currentModel?.pageSize || this.pageSize} 
						CurrentCurrency={this.state.filterCurrency} 
						onOrderOrPageChange={(pageNumber: number, orderByField: JobSearch.IAmJobOrdersSorting) => this.setState({pageNumber: pageNumber, sortByField: orderByField})}
						/>
							
				</div>
				<Footer />
			</div>	
		)
	}
}

export default Homepage2;