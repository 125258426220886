import React, {Component} from 'react';

class Footer extends Component{
	render(){
		return(
			<footer className="site-footer">
				<div className="footer-top">
					<div className="container">
						<div className="row">
						</div>
					</div>
				</div>
				
				<div className="footer-bottom">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 text-center">
								<span> © Copyright by All-IT.jobs<i className="fa fa-heart m-lr5 text-red heart"></i>
								All rights reserved.</span> 
							</div>
						</div>
						<div className="row">
							<div className="col-lg-12 text-center">
								<ul className="list-inline">
									<li className="list-inline-item"><a href="/termsOfService">Warunki korzystania</a> </li>
									<li className="list-inline-item"><a href="/privacyPolicy">Polityka prywatności</a> </li>
									<li className="list-inline-item"><a href="mailto: biuro@all-it.jobs">Kontakt</a> </li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</footer>
		)
	}
}

export default Footer;