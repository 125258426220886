import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import { SessionAuth } from 'supertokens-auth-react/recipe/session';
import { resolve } from 'inversify-react';
import * as User from '../../services/User';

import { ToastItem, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Cache from "../../services/Cache";
import Session from "supertokens-auth-react/recipe/session";
import { Guid } from 'typescript-guid';
import Moment from 'react-moment';

import './SaveThisApplicationInHistoryModal.css';
import Toggle from 'react-toggle';
import 'react-toggle/style.css'

interface IAmAppliedResumeWithCompanyAndJobPosition {
    applicationId: Guid;
    resumeFileName: string;
    companyName: string;
    jobPositionName: string;
    dateApplied: Date;
}

export enum ShowSaveThisApplicationInHistoryModalType {
	ShowApply,
	ShowAlreadyApplied,
	Hide
}

export interface SaveThisApplicationInHistoryModalComponentOptions {
    showModal: ShowSaveThisApplicationInHistoryModalType;
    jobOfferId?: number;
    onClose: () => void;
}

export class SaveThisApplicationInHistoryModalComponent extends Component<SaveThisApplicationInHistoryModalComponentOptions> {
    @resolve(User.Types.AppliedJobSaver)
    private readonly appliedJobSaver!: User.ISaveAppliedJob;

    @resolve(User.Types.AppliedResumeFilesRetriever)
    private readonly appliedResumeFilesRetriever!: User.IRetrieveAppliedResumeFiles;

    @resolve(User.Types.AppliedJobsRetriever)
    private readonly appliedJobsRetriever!: User.IRetrieveAppliedJobs;

    @resolve(User.Types.AppliedResumeFileSaver)
    private readonly appliedResumeFileSaver!: User.ISaveAppliedResumeFile;

    @resolve(User.Types.UserSettingsRetriever)
    private readonly userSettingsRetriever!: User.IRetrieveUserSettings;

    @resolve(User.Types.UserSettingsRetriever)
    private readonly userSettingsSaver!: User.ISaveUserSettings;

    state = {
        showModal: ShowSaveThisApplicationInHistoryModalType.Hide,
        showInfoForLoggedUserAboutPossibilityToSaveJobApplicationsPreference: false,
        jobOfferId: 0,
        onClose: () => {},
        appliedResumeFiles: [] as IAmAppliedResumeWithCompanyAndJobPosition[],
    }

    constructor(props: SaveThisApplicationInHistoryModalComponentOptions) {
        super(props);

        this.state = {
            showModal: props.showModal,
            showInfoForLoggedUserAboutPossibilityToSaveJobApplicationsPreference: true,
            jobOfferId: props.jobOfferId || 0,
            onClose: props.onClose || (() => {}),
            appliedResumeFiles: [] as IAmAppliedResumeWithCompanyAndJobPosition[],
        };

        this.onYesClick = this.onYesClick.bind(this);
        this.onNoClick = this.onNoClick.bind(this);
        this.onNewCVSelected = this.onNewCVSelected.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.notifyAboutSuccessfulApplication = this.notifyAboutSuccessfulApplication.bind(this);
        this.notifyAboutUnsuccessfulApplication = this.notifyAboutUnsuccessfulApplication.bind(this);
        this.onCloneCVSelected = this.onCloneCVSelected.bind(this);
        this.changeShowInfoForLoggedUserAboutPossibilityToSaveJobApplicationsPreference = this.changeShowInfoForLoggedUserAboutPossibilityToSaveJobApplicationsPreference.bind(this);
    }

    async componentDidUpdate(prevProps: SaveThisApplicationInHistoryModalComponentOptions) {
        if(!await Session.doesSessionExist()) {
            return;
        }

        if (prevProps !== this.props) {
            this.setState({
                showModal: this.props.showModal,
                jobOfferId: this.props.jobOfferId,
            })

            if (this.props.showModal !== ShowSaveThisApplicationInHistoryModalType.Hide) {
                const resumeFiles = await this.appliedResumeFilesRetriever.retrieve();                
                const appliedJobs = await this.appliedJobsRetriever.retrieveByIds(resumeFiles.map(x => x.applicationId));

                const appliedResumeFiles = appliedJobs
                    .map(x => {
                        const resume = resumeFiles.find(y => y.applicationId.equals(x.id));
                        return {
                            applicationId: resume?.applicationId,
                            resumeFileName: resume?.fileName,
                            companyName: x.companyName,
                            jobPositionName: x.positionName,
                            dateApplied: x.dateApplied,
                        } as IAmAppliedResumeWithCompanyAndJobPosition;
                    })
                    .sort((a, b) => b.dateApplied.getTime() - a.dateApplied.getTime());

                this.setState({ appliedResumeFiles: appliedResumeFiles });

                await this.retrieveInfoForLoggedUserAboutPossibilityToSaveJobApplicationsPreferenceAndSaveJobApplicationInHistoryAutomatically();
            }
        }
    }

    private async retrieveInfoForLoggedUserAboutPossibilityToSaveJobApplicationsPreferenceAndSaveJobApplicationInHistoryAutomatically(): Promise<void> {
        const userSettings = await this.userSettingsRetriever.retrieve();
        this.setState({ showInfoForLoggedUserAboutPossibilityToSaveJobApplicationsPreference: userSettings.notifications.showModalSaveJobToHistoryAfterApplyingToOffer });

        if (this.state.showModal === ShowSaveThisApplicationInHistoryModalType.ShowAlreadyApplied && !userSettings.notifications.showModalSaveJobToHistoryAfterApplyingToOffer) {
            await this.onYesClick();
        }
    }

    async componentDidMount(): Promise<void> {
        if(!await Session.doesSessionExist()) {
            return;
        }
        
        await this.retrieveInfoForLoggedUserAboutPossibilityToSaveJobApplicationsPreferenceAndSaveJobApplicationInHistoryAutomatically();
    }

    hideModal() {
        this.setState({ showModal: false });
        this.state.onClose();
    }

    async onYesClick() {
        try
        {
            const applicationId = Guid.create();
            await this.appliedJobSaver.save(applicationId, this.state.jobOfferId);
        }
        catch (e)
        {
            this.notifyAboutUnsuccessfulApplication();
            return;
        }

        this.notifyAboutSuccessfulApplication(); 

        this.setState({ showModal: false });
        this.state.onClose();
    }

    private notifyAboutSuccessfulApplication() {
        toast.success(
            <div>
                <span>Aplikacja zapisana w historii</span> 
                <div>
                    <a href="/user/appliedJobs" className="site-button mt-2">Zobacz historię</a>
                </div>
            </div>); //todo: translate!
    }

    private notifyAboutUnsuccessfulApplication() {
        toast.error(<div><div>Nie udało się zapisać aplikacji. Sprawdź czy się nie zapisała w historii i jeśli nie - spróbuj ponownie.</div><br/><div className='text-center'><a href="/user/appliedJobs" className="site-button mt-2">Zobacz historię</a></div></div>, { autoClose: false }); //todo: translate!
    }

    onNoClick() {
        this.setState({ showModal: false });

        this.state.onClose();
    }

    async onNewCVSelected(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files === null || event.target.files.length === 0) {
            return;
        }

        const file = event.target.files[0];
        const fileSize = file.size; 
        const maxFileSize = 15 * 1024 * 1024; //todo: move to config!

        if (fileSize > maxFileSize) {
            toast.error("Plik jest za duży. Maksymalny rozmiar to 15MB."); //todo: translate!            
            return;
        }

        try
        {
            const applicationId = Guid.create();
            const cvSaverPromise = this.appliedResumeFileSaver.save(applicationId, file);
            const jobApplicationSaverPromise = this.appliedJobSaver.save(applicationId, this.state.jobOfferId);

            await Promise.all([cvSaverPromise, jobApplicationSaverPromise]);
        }
        catch (e)
        {
            this.notifyAboutUnsuccessfulApplication();
            return;
        }

        this.notifyAboutSuccessfulApplication();

        this.setState({ showModal: false });
        this.state.onClose();
    }

    async onCloneCVSelected(srcApplicationId: Guid) {
        try
        {
            const applicationId = Guid.create();
            const cvSaverPromise = this.appliedResumeFileSaver.saveFromOtherResume(applicationId, srcApplicationId);
            const jobApplicationSaverPromise = this.appliedJobSaver.save(applicationId, this.state.jobOfferId);

            await Promise.all([cvSaverPromise, jobApplicationSaverPromise]);
        }
        catch (e)
        {
            this.notifyAboutUnsuccessfulApplication();
            return;
        }

        this.notifyAboutSuccessfulApplication();

        this.setState({ showModal: false });
        this.state.onClose();
    }

    async changeShowInfoForLoggedUserAboutPossibilityToSaveJobApplicationsPreference(showInfoForLoggedUserAboutPossibilityToSaveJobApplicationsPreference: boolean): Promise<void> {
        const currentSettings = await this.userSettingsRetriever.retrieve();
        currentSettings.notifications.showModalSaveJobToHistoryAfterApplyingToOffer = showInfoForLoggedUserAboutPossibilityToSaveJobApplicationsPreference;
        await this.userSettingsSaver.save(currentSettings);
        this.setState({ showInfoForLoggedUserAboutPossibilityToSaveJobApplicationsPreference: showInfoForLoggedUserAboutPossibilityToSaveJobApplicationsPreference });

        if (this.state.showModal !== ShowSaveThisApplicationInHistoryModalType.Hide && !showInfoForLoggedUserAboutPossibilityToSaveJobApplicationsPreference) {
            await this.onYesClick();
        }
    }

    render() {
        return(
            <div>
                <SessionAuth doRedirection={false} requireAuth={true} key={"LoggedInUser"}>
                    <Modal show={this.state.showModal !== ShowSaveThisApplicationInHistoryModalType.Hide && (this.state.showInfoForLoggedUserAboutPossibilityToSaveJobApplicationsPreference)} keyboard={false} centered backdrop="static" onHide={this.onNoClick}>
                        <Modal.Header>
                            <Modal.Title>Zapisać twoją aplikację w historii?</Modal.Title> { /* //todo: fudged! translate! */ }
                        </Modal.Header>
                        <Modal.Body>
                            Zapisując aplikację, będziesz mógł ją łatwo znaleźć w historii aplikacji i przypomnieć sobie warunki na jakie aplikowałeś. Dodatkowo, damy Ci znać jak będziesz zbyt intensywnie aplikował do tej samej firmy.
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-xs-4 col-12 mb-2">
                                        <Button variant="primary" onClick={this.onNoClick} className="w-100">Nie, nie zapisuj</Button>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-xs-4 col-12 mb-2">
                                        <Button variant="primary" onClick={this.onYesClick} className="w-100" >Zapisz bez CV</Button>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-xs-4 col-12 mb-2">
                                        <DropdownButton id="dropdown-basic-button" title="Zapisz z CV" className="w-100">
                                            <Dropdown.Item as="button">
                                                Dołącz CV <input type="file" id='file' name='file' onChange={(e) => {this.onNewCVSelected(e);}}/> {/* //todo: translate */}
                                            </Dropdown.Item>
                                            {
                                                this.state.appliedResumeFiles.length > 0 && <Dropdown.Item disabled>Ostatnio zapisane CV:</Dropdown.Item>
                                            }
                                            {
                                                this.state.appliedResumeFiles.map((resumeWithOtherData, index) => {
                                                    return <Dropdown.Item as="button" key={resumeWithOtherData.applicationId.toString()} onClick={() => {this.onCloneCVSelected(resumeWithOtherData.applicationId);}}>{resumeWithOtherData.resumeFileName} 
                                                        <small> (
                                                            <span title={resumeWithOtherData.dateApplied.toLocaleString()}>
                                                                <Moment locale="pl" fromNow >{resumeWithOtherData.dateApplied}</Moment> 
                                                            </span>, {resumeWithOtherData.companyName}, {resumeWithOtherData.jobPositionName})
                                                        </small>
                                                    </Dropdown.Item>
                                                })
                                            }
                                        </DropdownButton>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-xs-12 col-12">
                                        <div className="d-flex">
                                            <center>
                                                <Toggle 
                                                    checked={!this.state.showInfoForLoggedUserAboutPossibilityToSaveJobApplicationsPreference} 
                                                    onChange={(e) => this.changeShowInfoForLoggedUserAboutPossibilityToSaveJobApplicationsPreference(!e.target.checked) } /><span>&nbsp;Nie pokazuj więcej <small>(można zmienić w panelu ustawień)</small></span>
                                            </center>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </SessionAuth>
                <SessionAuth doRedirection={false} requireAuth={false} key={"NotLoggedInUser"}>
                    <ShowInfoForNotLoggedUserComponent />
                </SessionAuth>
            </div>
        )
    }
}

class ShowInfoForNotLoggedUserComponent extends Component {
    private readonly showInfoForNotLoggedUserAboutPossibilityToSaveJobApplicationsPreferenceKey: string = 'showInfoForNotLoggedUserAboutPossibilityToSaveJobApplicationsPreference';
    private readonly aMillionYearsInMilliseconds = 1000 * 365 * 24 * 60 * 60 * 1000;
    private readonly toastId: string = "4a59423c-c992-432f-bf07-20d3686a4455";

    @resolve(Cache.Types.CacheService)
    private readonly cacheService!: Cache.ICacheData;

    constructor(props: any) {
        super(props);
    }

    async componentDidMount(): Promise<void> {
        var isUserLoggedIn = await Session.doesSessionExist();
        if (isUserLoggedIn) {
            return;
        }

        const showInfoForNotLoggedUserAboutPossibilityToSaveJobApplicationsPreference = this.cacheService.get(this.showInfoForNotLoggedUserAboutPossibilityToSaveJobApplicationsPreferenceKey);
        
        if((showInfoForNotLoggedUserAboutPossibilityToSaveJobApplicationsPreference === null || showInfoForNotLoggedUserAboutPossibilityToSaveJobApplicationsPreference === true)) {
            toast.info(
                <>
                    <div>Czy wiesz że jako zalogowany użytkownik możesz zapisać swoją aplikację w historii i budować w ten sposób swoją historię by móc ją przeglądać i analizować?</div>
                    <div style={{alignItems: 'center'}}>
                        <hr />
                        <label style={{alignItems: 'center', display: 'flex'}}>
                            <Toggle onChange={(e) => { this.cacheService.put(this.showInfoForNotLoggedUserAboutPossibilityToSaveJobApplicationsPreferenceKey, !e.target.checked, this.aMillionYearsInMilliseconds); } } /><span>&nbsp;Nie pokazuj więcej</span>
                        </label>
                    </div>
                </>, { autoClose: false, toastId: this.toastId, closeOnClick: false });
        }
    }

    render() {
        return (<></>);
    }
}
