import { inject, injectable } from "inversify";
import { IInitialiseUserSettings, IRetrieveUserSettings, ISaveUserSettings, IUserSettings, UserSettingsKey } from "./abstraction";
import * as config from "../../config";
import Session from 'supertokens-auth-react/recipe/session';
import { SettingsService, OpenAPI } from "@all-it/api.users.client";
import * as Cache from "../Cache";

@injectable()
export class UserSettingsService implements IRetrieveUserSettings, ISaveUserSettings, IInitialiseUserSettings {
    @inject(config.Types.UserSettingsSettings)
    private readonly _userSettingsApiSettings!: config.IKeepUserSettingsApiData;

    @inject(Cache.Types.CacheService)
    private readonly _cacheService!: Cache.ICacheData;

    @inject(config.Types.GeneralApplicationSettings)
    private readonly _generalAppData!: config.IKeepGeneralApplicationData;

    async retrieve(): Promise<IUserSettings> {
        const cachedSettings = this._cacheService.get<IUserSettings>(this.getUserSettingKey());
        if (cachedSettings != null) {
            return cachedSettings;
        }

        const settings = await this.retrieveAndCacheUserSettings();

        return settings;
    }

    private async retrieveAndCacheUserSettings(): Promise<IUserSettings> {
        const settings = await this.getUserSettings();

        this._cacheService.put(this.getUserSettingKey(), settings, this.getUserSettingTtl());

        return settings;
    }

    async initialise(): Promise<void> {
        await this.retrieveAndCacheUserSettings();
    }

    unInitialise(): Promise<void> {
        this._cacheService.remove(this.getUserSettingKey());
        return Promise.resolve();
    }

    async save(settings: IUserSettings): Promise<void> {
        const jwt = await Session.getAccessToken();    

        OpenAPI.BASE = this._userSettingsApiSettings.userSettingsApiUrl;
        OpenAPI.TOKEN = jwt;

        await SettingsService
            .settingsPost({
                notificationSettings: {
                    mailMeAboutNews: settings.notifications.mailMeAboutNews,
                    showModalSaveJobToHistoryAfterRedirectingToOffer: settings.notifications.showModalSaveJobToHistoryAfterApplyingToOffer
                }
            });

        this._cacheService.put(this.getUserSettingKey(), settings, this.getUserSettingTtl());
    }

    private getUserSettingTtl(): number {
        return 60 * 60 * 1000;
    }

    private getUserSettingKey(): string {
        return `${UserSettingsKey}_${this._generalAppData.applicationBuildId}`;
    }

    private async getUserSettings(): Promise<IUserSettings> {
        const jwt = await Session.getAccessToken();

        OpenAPI.BASE = this._userSettingsApiSettings.userSettingsApiUrl;
        OpenAPI.TOKEN = jwt;

        let settings = await SettingsService.settingsGet();

        if (settings == null) {
            settings = {
                notifications: {
                    mailMeAboutNews: true,
                    showModalSaveJobToHistoryAfterApplyingToOffer: true,
                }
            };
        }

        return {
            notifications: {
                mailMeAboutNews: settings!.notifications!.mailMeAboutNews!,
                showModalSaveJobToHistoryAfterApplyingToOffer: settings!.notifications!.showModalSaveJobToHistoryAfterApplyingToOffer!
            }
        };
    }
}