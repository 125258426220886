import { inject, injectable } from "inversify";
import { IGetKnownTechnologies } from "./abstraction";
import * as config from "../../config";
import * as Cache from "../Cache";
import Session from 'supertokens-auth-react/recipe/session';
import { KnownTechnologiesService, OpenAPI } from "@all-it/api.joboffers.client";

@injectable()
export class KnownTechnologiesForSearchService implements IGetKnownTechnologies {
    @inject(config.Types.JobsApiSettings)
    private readonly _searchApiSettings!: config.IKeepJobsApiData;

    @inject(Cache.Types.CacheService)
    private readonly _cache!: Cache.ICacheData;

    async get(): Promise<string[]> {
        const cacheKey = "JobOffersKnownTechnologies";
        const knownTechnologiesCached = this._cache.get<string[]>(cacheKey);
        if (knownTechnologiesCached != null) 
        {
            return knownTechnologiesCached!;
        }
        
        OpenAPI.BASE = this._searchApiSettings.jobsOfferApiUrl;

        if (await Session.doesSessionExist()) {
            const jwt = await Session.getAccessToken();

            OpenAPI.TOKEN = jwt;
        }

        const knownTechnologies = await KnownTechnologiesService.knownTechnologiesGetAll();
        const knownTechnologiesSorted = knownTechnologies.sort((a: string, b: string) => {
            return a > b ? 1 : -1;
        });

        this._cache.put(cacheKey, knownTechnologiesSorted, 1000 * 60 * 60); //todo: create type like timespan with factories c# style like 'FromSeconds' https://stackoverflow.com/questions/49216952/how-to-work-with-timespan-in-typescript
        
        return knownTechnologies;
    }
}