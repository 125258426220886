import React, {Component} from 'react';
import Moment from 'react-moment';
import {Link} from 'react-router-dom';
import './SearchJobListItem.css'

import {IAmJobFound, IAmJobFoundWage, IAmJobWageTimeUnit} from '../../services/JobSearch/';
import { roundNumber } from '../../extensions/mathExtensions';
import { highlightText } from '../../extensions/highlightText';

function formatWage(wage: IAmJobFoundWage): string {
    if (wage === undefined || wage.wageRate === undefined) {
        return "";
    }

    const timeUnitString = formatTimeUnit(IAmJobWageTimeUnit.Monthly);
    const fromRounded = roundNumber(wage.wageRateMonthly.from, 2);
    const toRounded = roundNumber(wage.wageRateMonthly.to, 2);
    
    if (fromRounded === toRounded) {
        return fromRounded + " " + wage.wageRateMonthly.currency + " " + timeUnitString;
    }

    return fromRounded + " - " + toRounded+ " " + wage.wageRateMonthly.currency + " " + timeUnitString;
}

function formatTimeUnit(timeUnit: IAmJobWageTimeUnit): string {
    //todo: fudged! translation needed and this definitely could've been done better!
    switch(timeUnit) {
        case IAmJobWageTimeUnit.Daily:
            return "Dziennie";
        case IAmJobWageTimeUnit.Hourly:
            return "Godzinowo";
        case IAmJobWageTimeUnit.Monthly:
            return "Miesięcznie";
        case IAmJobWageTimeUnit.Weekly:
            return "Tygodniowo";
        case IAmJobWageTimeUnit.Yearly:
            return "Rocznie";
        default:
            return "";
    }
}

interface IKeepTheState {
    destinationUrl: string;
    destinationUrlTemplate: string;
}

class SearchJobListItem extends Component<IAmJobFound, IKeepTheState>{
    constructor(props: IAmJobFound) {
        super(props);

        const destUrl = `/job/${this.props.hashCode}`;
        this.state = {destinationUrl: destUrl, destinationUrlTemplate: destUrl};

        this.linkOnClick = this.linkOnClick.bind(this);
    }

    linkOnClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
        if (this.props.searchTextTags !== undefined && this.props.searchTextTags.length > 0) {
            let currentUrl = this.state.destinationUrlTemplate;
            if (currentUrl.endsWith("/")) {
                currentUrl = currentUrl.substring(0, currentUrl.length - 1);
            }

            const newDestinationUrl = currentUrl + "/" + this.props.searchTextTags.map(tag => encodeURIComponent(tag)).join(",");
            this.setState({destinationUrl: newDestinationUrl});
        }
    }

    render() {
        const companyImageAvailable = this.props.companyLogoUrl !== undefined && this.props.companyLogoUrl !== "" && this.props.companyLogoUrl !== null && this.props.companyLogoUrl !== "about:blank";
        const bgImage = companyImageAvailable ? this.props.companyLogoUrl : require('./../../images/company/no_company_logo.png');
        return <Link to={this.state.destinationUrl} onClick={this.linkOnClick} target='_blank' rel="noreferrer noopener">
            <div className="post-bx" >
                <div className="container">
                    <div className="row">
                        <div className='col-2 col-sm-2 col-md-1 col-lg-1 col-xl-1'>
                            <img src={bgImage} alt={this.props.companyName?.trim()} title={this.props.companyName?.trim()} />
                        </div>
                        <div className='col-10 col-sm-10 col-md-11 col-lg-11 col-xl-11 p-r0'>
                            <div className='container'>
                                <div className='row'>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-r0">
                                        <span className='float-left'>
                                            <h5 dangerouslySetInnerHTML={{__html: highlightText(this.props.positionName, this.props.searchTextTags)}}></h5>
                                        </span>
                                        &nbsp;
                                        <span className="float-right">
                                            <div className="job-time">
                                                <span title={this.props.dateAdded.toLocaleString()}>
                                                    <i className="fa fa-clock-o">&nbsp;</i> 
                                                    <Moment locale="pl" fromNow>{this.props.dateAdded}</Moment>
                                                </span> 
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-r0">
                                        <span title={this.props.locations?.join(", ")}>
                                            <i className="fa fa-map-marker"></i> {this.props.locations?.[0] || ""}{this.props.locations?.length > 1 ? ` + ${this.props.locations.length - 1}` : ""}
                                        </span>
                                        &nbsp;&nbsp;
                                        <span title={this.props.seniorityLevels?.join(", ")}>
                                            <i className="fa fa-user"></i> {this.props.seniorityLevels?.[0] || ""}{this.props.seniorityLevels?.length > 1 ? ` + ${this.props.seniorityLevels.length - 1}` : ""}
                                        </span>
                                        &nbsp;&nbsp;
                                        {
                                            this.props.wages?.length > 0 &&
                                            <span title={this.props.wages?.map(wage => formatWage(wage)).join(", ")} className="float-right p-r0">
                                                <i className="fa fa-money"></i> {formatWage(this.props.wages?.[0] || {})}{this.props.wages?.length > 1 ? ` + ${this.props.wages.length - 1}` : ""}
                                            </span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    }
}

export { SearchJobListItem };