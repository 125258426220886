import React from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Toggle from 'react-toggle';
import { resolve } from "inversify-react";
import * as User from '../../../services/User';
import { toast } from 'react-toastify';

interface IKeepTheState {
    mailMeAboutNews: boolean;
    showModalSaveJobToHistoryAfterApplyingToOffer: boolean;
}

class UserSettings extends React.Component<IKeepTheState> {
    @resolve(User.Types.UserSettingsRetriever)
    private readonly _userSettingsRetriever!: User.IRetrieveUserSettings;

    @resolve(User.Types.UserSettingsSaver)
    private readonly _userSettingsSaver!: User.ISaveUserSettings;

    state = {
        mailMeAboutNews: true,
        showModalSaveJobToHistoryAfterApplyingToOffer: true
    }

    constructor(props: any) {
        super(props);

        this.saveSettings = this.saveSettings.bind(this);
    }

    async componentDidMount(): Promise<void> {
        const settings = await this._userSettingsRetriever.retrieve();

        this.setState({
            mailMeAboutNews: settings.notifications.mailMeAboutNews,
            showModalSaveJobToHistoryAfterApplyingToOffer: settings.notifications.showModalSaveJobToHistoryAfterApplyingToOffer
        });
    }

    async saveSettings(): Promise<void> {
        await this._userSettingsSaver.save({
            notifications: {
                mailMeAboutNews: this.state.mailMeAboutNews,
                showModalSaveJobToHistoryAfterApplyingToOffer: this.state.showModalSaveJobToHistoryAfterApplyingToOffer
            }
        });

        toast.success("Ustawienia zostały zapisane");
    }

    render() {
        return (
        <div className="page-wraper">
				<Header />	

				<div className="page-content">
                    <div className="content-block">
                        <div className="section-full bg-white p-t50 p-b20">
                            <div className="container">
                                <h5 className="text-center">Ustawienia</h5>
                                <hr />
                                <div className="row">
                                    <h6 className="font-weight-700 pull-left text-uppercase">Notyfikacje e-mail</h6>
                                    <br />
                                    <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12">
                                        <div className="form-group">
                                            <Toggle id='mailMeAboutNews' checked={this.state.mailMeAboutNews} onChange={(e) => this.setState({ mailMeAboutNews: e.target.checked })} />&nbsp;Chcę otrzymywać powiadomienia o nowościach
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <h6 className="font-weight-700 pull-left text-uppercase">Powiadomienia na stronie</h6>
                                    <br />
                                    <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12">
                                        <div className="form-group">
                                            <Toggle id='showModalSaveJobToHistoryAfterApplyingToOffer' checked={this.state.showModalSaveJobToHistoryAfterApplyingToOffer} onChange={(e) => this.setState({ showModalSaveJobToHistoryAfterApplyingToOffer: e.target.checked })} />&nbsp;Pokaż okno dialogowe z możliwością zapisania oferty do historii po złożeniu aplikacji
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12 ">
                                        <div className="form-group float-right">
                                            <button type="button" className="site-button" onClick={this.saveSettings}>Zapisz</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
				</div>
				<Footer />
			</div>	
        );
    }
}

export default UserSettings;