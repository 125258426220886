import React, {Component} from 'react';
import { IAmJobFound, IAmJobOrdersSorting } from '../../services/JobSearch';
import { SearchJobListItem } from './SearchJobListItem';
import Select from 'react-select';
import { PaginationRemote } from './Pagination';
import Session from 'supertokens-auth-react/recipe/session';
import { checkIfUserInAnyRole, getUserRolesFromToken, ProtectionRoles } from '../Element/ProtectedBase';
import { PagedResult } from '../../services/PagedResult';

interface IKeepTheState {
	currentModel: PagedResult<IAmJobFound> | null;
	allOrderByFields: { label: string; value: string; }[];
	currentOrderBy: { label: string; value: string };
	currentCurrency: string;

	shouldScrollToJobs: boolean;
}

export interface JobSectionOptions {
	CurrentModel: PagedResult<IAmJobFound>,
	MaxJobsPerPage: number,
	CurrentCurrency: string,

	onOrderOrPageChange: (pageNumber: number, orderByField: IAmJobOrdersSorting) => void
}

class Jobsection extends Component<JobSectionOptions, IKeepTheState>{
	private readonly jobsPageChangeScrollTo: React.RefObject<any>;
	
	public constructor(props: JobSectionOptions) {
		super(props);

		const firstSelectedOrderByField = {label: "od najnowszego", value: IAmJobOrdersSorting.Newest};

		this.state = {
			currentModel: null,
			allOrderByFields: [
				firstSelectedOrderByField,
				{label: "od najstarszego", value: IAmJobOrdersSorting.Oldest},
				{label: "od najlepiej płatnego", value: IAmJobOrdersSorting.BestSalary},
			],
			currentOrderBy: firstSelectedOrderByField,
			currentCurrency: props.CurrentCurrency,
			shouldScrollToJobs: false
		};

		this.onChangePage = this.onChangePage.bind(this);
		this.scrollToJobs = this.scrollToJobs.bind(this);
		this.onOrderByFieldChange = this.onOrderByFieldChange.bind(this);

		this.jobsPageChangeScrollTo = React.createRef();
	}

	async componentDidMount(): Promise<void> {
		if(await Session.doesSessionExist()) {
			const userRoles = await getUserRolesFromToken();

			if (checkIfUserInAnyRole([ProtectionRoles.Admin, ProtectionRoles.Tester], userRoles)) {
				const randomOrderByField = {label: "losowo", value: IAmJobOrdersSorting.Random}; //todo: fudged! translation!
				const orderByCompany = {label: "po nazwie firmy rosnąco", value: IAmJobOrdersSorting.CompanyNameAsc}; //todo: fudged! translation!
				const orderByCompanyDesc = {label: "po nazwie firmy malejąco", value: IAmJobOrdersSorting.CompanyNameDesc}; //todo: fudged! translation!

				this.setState({allOrderByFields: [...this.state.allOrderByFields, randomOrderByField, orderByCompany, orderByCompanyDesc] });
			}
		}		
	}

	componentDidUpdate(prevProps: JobSectionOptions) {
		if (prevProps.CurrentModel !== this.props.CurrentModel) {
			this.setState({ currentModel: this.props.CurrentModel, currentCurrency: this.props.CurrentCurrency, shouldScrollToJobs: this.state.currentModel !== null }, () => { this.scrollToJobs(); });
		}
	}

	onChangePage(pageNumber: number) {
		this.props.onOrderOrPageChange(pageNumber, this.state.currentOrderBy.value as IAmJobOrdersSorting);
	}

	onOrderByFieldChange(orderByField: {label: string, value: string} | null) {
		this.setState({currentOrderBy: orderByField!}, () => {this.props.onOrderOrPageChange(1, orderByField!.value as IAmJobOrdersSorting);});		
	}

	scrollToJobs() {
		if (!this.state.shouldScrollToJobs) {
			return;
		}
		
		this.jobsPageChangeScrollTo.current.scrollIntoView({ block: 'start', behavior: 'smooth'});
	}

	render(){
		const isInInitialState = this.state.currentModel == null;
		const currentModelToRender = (this.state.currentModel?.items.length || 0) > 0;

		return(
			<div>
				<div ref={this.jobsPageChangeScrollTo} />
				<div className="section-full bg-white ">
					
					<div className="container">
						{
							currentModelToRender &&
							<div className="row">&nbsp;</div>
						}
						{
							currentModelToRender &&
							<div className="row">
								<div className="col-xl-9 col-lg-9 col-sm-7 col-md-8 col-xs-8"></div>
								<div className="col-xl-3 col-lg-3 col-sm-5 col-md-4 col-xs-4 col-12" >
									<Select options={this.state.allOrderByFields} closeMenuOnSelect={true}
										onChange={this.onOrderByFieldChange} 
										placeholder="Sortowanie po: " value={ this.state.currentOrderBy } />
								</div>
							</div>
						}
						{
							currentModelToRender &&
							<div className="row">&nbsp;</div>
						}
						{
							currentModelToRender && 
							<div className="row">
								<div className="col-lg-12 col-md-12 col-xs-12 m-b40">
									<ul className="post-job-bx browse-job">
										{
											this.state.currentModel?.items.map((singleJobOffer, index) => (
												<li key={singleJobOffer.hashCode}>
													<SearchJobListItem {...singleJobOffer} />
												</li>
											))
										}	 								
									</ul>
									<div className="d-flex justify-content-center">
										<PaginationRemote currentPage={this.state.currentModel?.pageNumber || 1} totalPages={this.state.currentModel?.totalPagesCount || 0} onChangePage={this.onChangePage} />
									</div>
								</div>
							</div>
						}
						{ 
							!isInInitialState && 
							!currentModelToRender &&
							<div className="row">
								<div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12">
									<div className="d-flex justify-content-center">
										<h3>Brak wyników</h3> { /* todo: fudged! */  }
									</div>
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		)
	}
}
export default Jobsection;

