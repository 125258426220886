import { inject, injectable } from "inversify";
import { IAmJobDescription, IAmJobLocation, IAmJobMethodology, IAmJobOfferSnapshot, IAmJobSeniorityLevel, IAmJobWage, IAmJobWageTimeUnit, IGetJobs } from "./abstraction";
import * as Cache from "../Cache";
import { OpenAPI, JobOfferGetterService, Jd } from "@all-it/api.joboffers.client";
import { Guid } from "typescript-guid";
import * as config from "../../config";
import Session from 'supertokens-auth-react/recipe/session';
import { convertEnumGeneric } from "../../extensions/enumsExtensions";

interface IAmExtendedJobOfferSnapshot extends IAmJobOfferSnapshot {
    jobOfferId: number;
}

@injectable()
export class GetJobsService implements IGetJobs {
    @inject(config.Types.JobsApiSettings)
    private readonly _apiSettings!: config.IKeepJobsApiData;

    @inject(Cache.Types.CacheService)
    private readonly _cache!: Cache.ICacheData;

    async get(hashCode: Guid): Promise<IAmJobDescription> {
        const cacheKey = `JobDescription ${hashCode.toString()}`;
        const jobDescription = this._cache.get<IAmJobDescription>(cacheKey);
        if (jobDescription != null) {
            return jobDescription;
        }

        OpenAPI.BASE = this._apiSettings.jobsOfferApiUrl;

        if (await Session.doesSessionExist()) {
            const jwt = await Session.getAccessToken();

            OpenAPI.TOKEN = jwt;
        }

        const jobDescriptionRetrieved = await JobOfferGetterService.jobOfferGetterGet(hashCode.toString());
        const finalLocations = this.extractJobLocationsTakingIntoAccountExactDuplicates(jobDescriptionRetrieved);
        const finalSnapshots = this.extractSnapshotsTakingIntoAccountExactDuplicates(jobDescriptionRetrieved);
        
        const jdToReturn: IAmJobDescription = {
            id: jobDescriptionRetrieved.id!,
            company: {
                name: jobDescriptionRetrieved.companyDetails!.name!,
                url: jobDescriptionRetrieved.companyDetails!.uri!,
                imgUrl: jobDescriptionRetrieved.companyDetails!.imgUri!
            },
            methodologies: jobDescriptionRetrieved.methodologies!.map(val => {
                const methodology: IAmJobMethodology = {
                    description: val.description!,
                    values: val.values!
                };

                return methodology;
            }),
            niceToHaveAttributes: jobDescriptionRetrieved.niceToHaveAttributes!,
            dailyTasks: jobDescriptionRetrieved.dailyTasks!,
            originalUri: jobDescriptionRetrieved.originalUrl!,
            positionDescription: jobDescriptionRetrieved.positionDescription!,
            positionName: jobDescriptionRetrieved.positionName!,
            source: jobDescriptionRetrieved.jobOfferSource!,
            requiredAttributes: jobDescriptionRetrieved.requiredAttributes!,
            seniorityLevels: jobDescriptionRetrieved.seniorityLevels!.map(val => convertEnumGeneric(val, IAmJobSeniorityLevel)!),
            wages: jobDescriptionRetrieved.wageDetails!.map(val => {
                const timeUnit = convertEnumGeneric(val.timeUnit, IAmJobWageTimeUnit)!;
                const wage: IAmJobWage = {
                    additionalInfo: val.additionalInfo!,
                    currency: val.currency!,
                    timeUnit: timeUnit,
                    valueFrom: val.valueFrom!,
                    valueTo: val.valueTo!
                }

                return wage;
            }),
            locations: finalLocations,
            dateAdded: new Date(jobDescriptionRetrieved.dateAdded!),
            actualSnapshotUrl: jobDescriptionRetrieved.actualSnapshot?.url ?? "",
            snapshots: finalSnapshots
        };

        this._cache.put(cacheKey, jdToReturn, 1000 * 60 * 60);
        return jdToReturn;

        
    }

    extractSnapshotsTakingIntoAccountExactDuplicates(jobDescriptionRetrieved: Jd): IAmJobOfferSnapshot[] {
        let snapshots: IAmExtendedJobOfferSnapshot[] = [];

        jobDescriptionRetrieved.locations!.forEach(val => {
            const retVal: IAmExtendedJobOfferSnapshot = {
                location: val.city!,
                url: jobDescriptionRetrieved.actualSnapshot!.url ?? "",
                jobOfferId: jobDescriptionRetrieved.id!
            };

            snapshots.push(retVal);
        });

        if (jobDescriptionRetrieved.exactDuplicates !== undefined) {
            snapshots = snapshots.concat(jobDescriptionRetrieved.exactDuplicates!.flatMap(jdDuplicate => {
                return jdDuplicate.locations!.map(val => {
                    const retVal: IAmExtendedJobOfferSnapshot = {
                        location: val.city!,
                        url: jdDuplicate.actualSnapshot!.url ?? "",
                        jobOfferId: jdDuplicate.id!
                    };

                    return retVal;
                });
            }));
        }

        const cityToSnapshotMap = snapshots
            .reduce((acc: Map<string, IAmExtendedJobOfferSnapshot[]>, val: IAmExtendedJobOfferSnapshot) => {
                const mapKey = val.location;
                let mapVal = acc.get(mapKey);
                if (!mapVal) {
                    mapVal = [] as IAmExtendedJobOfferSnapshot[];
                    acc.set(mapKey, mapVal);
                }

                mapVal.push(val);
                return acc;
            }, new Map<string, IAmExtendedJobOfferSnapshot[]>()) as Map<string, IAmExtendedJobOfferSnapshot[]>;

        const cityToEarliestSnapshotMap = [] as IAmExtendedJobOfferSnapshot[];

        cityToSnapshotMap.forEach((vals: IAmExtendedJobOfferSnapshot[]) => {
            const earliestJobOffer = vals.sort((a: IAmExtendedJobOfferSnapshot, b: IAmExtendedJobOfferSnapshot) => a.jobOfferId - b.jobOfferId)[0];

            cityToEarliestSnapshotMap.push(earliestJobOffer);
        });

        const finalSnapshots = cityToEarliestSnapshotMap.map((val: IAmExtendedJobOfferSnapshot) => {
            const retVal: IAmJobOfferSnapshot = {
                location: val.location,
                url: val.url
            };

            return retVal;
        });

        return finalSnapshots;
    }

    extractJobLocationsTakingIntoAccountExactDuplicates(jobDescriptionRetrieved: Jd): IAmJobLocation[] {
        let locations: IAmJobLocation[] = [];
        if (jobDescriptionRetrieved.locations !== undefined) {
            locations = jobDescriptionRetrieved.locations!.map(val => {
                const retVal: IAmJobLocation = {
                    location: val.city!,
                    jobOfferId: jobDescriptionRetrieved.id!,
                    jobOfferUri: jobDescriptionRetrieved.originalUrl!
                };
                return retVal;
            });
        }

        if (jobDescriptionRetrieved.exactDuplicates !== undefined) {
            locations = locations.concat(jobDescriptionRetrieved.exactDuplicates!.flatMap(jdDuplicate => {
                return jdDuplicate.locations!.map(val => {
                    const retVal: IAmJobLocation = {
                        location: val.city!,
                        jobOfferId: jdDuplicate.id!,
                        jobOfferUri: jdDuplicate.originalUrl!
                    };
                    return retVal;
                });
            }));
        }

        const cityToLocationMap = locations
            .reduce((acc: Map<string, IAmJobLocation[]>, val: IAmJobLocation) => {
                const mapKey = val.location;
                let mapVal = acc.get(mapKey);
                if (!mapVal) {
                    mapVal = [] as IAmJobLocation[];
                    acc.set(mapKey, mapVal);
                }

                mapVal.push(val);
                return acc;
            }, new Map<string, IAmJobLocation[]>()) as Map<string, IAmJobLocation[]>;

        const cityToEarliestLocationMap = [] as IAmJobLocation[];

        cityToLocationMap.forEach((vals: IAmJobLocation[]) => {
            const earliestJobOffer = vals.sort((a: IAmJobLocation, b: IAmJobLocation) => a.jobOfferId - b.jobOfferId)[0];

            cityToEarliestLocationMap.push(earliestJobOffer);
        });

        const finalLocations = cityToEarliestLocationMap
            .map((val: IAmJobLocation) => { return val; })
            .sort((a: IAmJobLocation, b: IAmJobLocation) => a.location.toLocaleLowerCase().localeCompare(b.location.toLocaleLowerCase()));

        return finalLocations;
    }

}