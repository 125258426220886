import { Container } from 'inversify';
import { IKeepJobsApiData, Types as ConfigTypes, IConfigureAuthorisationServer, IKeepAuthorisationServerData, IKeepGeneralApplicationData, IKeepAppliedJobsApiData, IKeepScrappingGuardData, IKeepUserSettingsApiData, IConfigureGeneralApplicationData, IKeepSystemSettingsData} from './config'

import { IGetCurrenciesWithMinMaxForJobSearch, IGetJobs, IGetLocationsForJobSearch, ISearchForJobs, Types as SearchForJobsTypes } from './services/JobSearch';
import { SearchJobsService } from './services/JobSearch/SearchJobsService'
import { LocationsForJobSearchService } from './services/JobSearch/LocationsForJobSearchService';
import { CurrenciesWithMinMaxForJobSearchService } from './services/JobSearch/CurrenciesWithMinMaxForJobSearchService';

import { ApplicationConfiguration } from './config/ApplicationConfiguration';
import { AuthorisationServerConfigurator } from './config/AuthorisationServerConfigurator';

import { ICacheData, Types as CacheTypes } from './services/Cache';
import { CacheService } from './services/Cache/CacheService';
import { GetJobsService } from './services/JobSearch/GetJobsService';
import { ICommentAppliedJob, IInitialiseUserSettings, IRemoveAppliedJob, IRemoveAppliedResumeFile, IRetrieveAppliedJobs, IRetrieveAppliedResumeFiles, IRetrieveUserSettings, ISaveAppliedJob, ISaveAppliedResumeFile, ISaveUserSettings, Types as UserTypes } from './services/User';
import { AppliedJobsRetriever } from './services/User/AppliedJobsRetriever';
import { AppliedJobsRemover } from './services/User/AppliedJobRemover';
import { AppliedJobSaver } from './services/User/AppliedJobSaver';
import { AppliedResumeFilesRetriever } from './services/User/AppliedResumeFilesRetriever';
import { AppliedResumeFileSaver } from './services/User/AppliedResumeFileSaver';
import { AppliedResumeFilesRemover } from './services/User/AppliedResumeFilesRemover';
import { IApproveScrappingGuardPreview, IGetScrappingGuardPreviewResults, Types as ScrappingGuardTypes } from './services/Admin/ScrappingGuard';
import { ScrappingGuardPreviewService } from './services/Admin/ScrappingGuard/ScrappingGuardPreviewService';
import { IConfigureTrackingData, IKeepTrackingData } from './config/IKeepTrackingData';
import { TrackingDataConfigurator } from './config/TrackingDataConfigurator';
import { UserSettingsService } from './services/User/UserSettingsService';
import { GeneralApplicationDataConfigurator } from './config/GeneralApplicationDataConfigurator';
import { SystemSettingsService } from './services/Admin/SystemSettings/SystemSettingsService';
import { IGetSystemSettingsService, IUpdateSystemSettingsService, Types as SystemSettingsTypes } from './services/Admin/SystemSettings';
import { KnownTechnologiesForSearchService } from './services/JobSearch/KnownTechnologiesForSearchService';

const bootstrapper = new Container();

bootstrapper
    .bind<IConfigureAuthorisationServer>(ConfigTypes.AuthorisationServerConfigurator)
    .to(AuthorisationServerConfigurator)
    .inSingletonScope();

bootstrapper
    .bind<ISearchForJobs>(SearchForJobsTypes.SearchJobsService)
    .to(SearchJobsService)
    .inSingletonScope();

bootstrapper
    .bind<IGetCurrenciesWithMinMaxForJobSearch>(SearchForJobsTypes.CurrenciesWithMinMaxForJobSerachService)
    .to(CurrenciesWithMinMaxForJobSearchService)
    .inSingletonScope();

bootstrapper
    .bind<IGetLocationsForJobSearch>(SearchForJobsTypes.LocationsForJobSearchService)
    .to(LocationsForJobSearchService)
    .inSingletonScope();

bootstrapper
    .bind<KnownTechnologiesForSearchService>(SearchForJobsTypes.KnownTechnologiesForSearchService)
    .to(KnownTechnologiesForSearchService)
    .inSingletonScope();

bootstrapper
    .bind<IKeepJobsApiData>(ConfigTypes.JobsApiSettings)
    .to(ApplicationConfiguration)
    .inSingletonScope();

bootstrapper
    .bind<IKeepAuthorisationServerData>(ConfigTypes.AuthorisationServerDataSettings)
    .to(ApplicationConfiguration)
    .inSingletonScope();

bootstrapper
    .bind<IKeepGeneralApplicationData>(ConfigTypes.GeneralApplicationSettings)
    .to(ApplicationConfiguration)
    .inSingletonScope();

bootstrapper
    .bind<IGetJobs>(SearchForJobsTypes.GetJobsService)
    .to(GetJobsService)
    .inSingletonScope();

bootstrapper
    .bind<ICacheData>(CacheTypes.CacheService)
    .to(CacheService)
    .inSingletonScope();

bootstrapper
    .bind<IKeepAppliedJobsApiData>(ConfigTypes.AppliedJobsRetrieverSettings)
    .to(ApplicationConfiguration)
    .inSingletonScope();

bootstrapper
    .bind<IKeepAppliedJobsApiData>(ConfigTypes.AppliedJobsRemoverSettings)    
    .to(ApplicationConfiguration)
    .inSingletonScope();

bootstrapper
    .bind<IRetrieveAppliedJobs>(UserTypes.AppliedJobsRetriever)
    .to(AppliedJobsRetriever)
    .inSingletonScope();

bootstrapper
    .bind<IRemoveAppliedJob>(UserTypes.AppliedJobsRemover)
    .to(AppliedJobsRemover)
    .inSingletonScope();    

bootstrapper
    .bind<ISaveAppliedJob>(UserTypes.AppliedJobSaver)
    .to(AppliedJobSaver)    
    .inSingletonScope();

bootstrapper
    .bind<ICommentAppliedJob>(UserTypes.AppliedJobCommenter)
    .to(AppliedJobSaver)
    .inSingletonScope();

bootstrapper
    .bind<IKeepAppliedJobsApiData>(ConfigTypes.AppliedResumeFilesRetrieverSettings)
    .to(ApplicationConfiguration)

bootstrapper
    .bind<IRetrieveAppliedResumeFiles>(UserTypes.AppliedResumeFilesRetriever)
    .to(AppliedResumeFilesRetriever);

bootstrapper
    .bind<ISaveAppliedResumeFile>(UserTypes.AppliedResumeFileSaver)
    .to(AppliedResumeFileSaver);

bootstrapper
    .bind<IRemoveAppliedResumeFile>(UserTypes.AppliedResumeFileRemover)
    .to(AppliedResumeFilesRemover);

bootstrapper
    .bind<IKeepScrappingGuardData>(ConfigTypes.ScrappingGuardSettings)
    .to(ApplicationConfiguration);

bootstrapper
    .bind<IGetScrappingGuardPreviewResults>(ScrappingGuardTypes.GetScrappingGuardPreviewResultsService)
    .to(ScrappingGuardPreviewService);

bootstrapper
    .bind<IApproveScrappingGuardPreview>(ScrappingGuardTypes.ApproveScrappingGuardPreviewService)
    .to(ScrappingGuardPreviewService);  
    
bootstrapper
    .bind<IKeepTrackingData>(ConfigTypes.TrackingDataSettings)
    .to(ApplicationConfiguration);    

bootstrapper
    .bind<IConfigureTrackingData>(ConfigTypes.TrackingDataConfigurator)
    .to(TrackingDataConfigurator);

bootstrapper
    .bind<IKeepUserSettingsApiData>(ConfigTypes.UserSettingsSettings)
    .to(ApplicationConfiguration);

bootstrapper
    .bind<IKeepSystemSettingsData>(ConfigTypes.SystemSettingsSettings)
    .to(ApplicationConfiguration);    

bootstrapper
    .bind<IRetrieveUserSettings>(UserTypes.UserSettingsRetriever)
    .to(UserSettingsService);

bootstrapper
    .bind<ISaveUserSettings>(UserTypes.UserSettingsSaver)
    .to(UserSettingsService);

bootstrapper
    .bind<IInitialiseUserSettings>(UserTypes.UserSettingsInitialiser)
    .to(UserSettingsService);    

bootstrapper
    .bind<IConfigureGeneralApplicationData>(ConfigTypes.GeneralApplicationDataConfigurator)
    .to(GeneralApplicationDataConfigurator);

bootstrapper
    .bind<IGetSystemSettingsService>(SystemSettingsTypes.GetSystemSettingsService)
    .to(SystemSettingsService);

bootstrapper
    .bind<IUpdateSystemSettingsService>(SystemSettingsTypes.UpdateSystemSettingsService)
    .to(SystemSettingsService);    

export { bootstrapper };

bootstrapper.get<IConfigureAuthorisationServer>(ConfigTypes.AuthorisationServerConfigurator).Configure();
bootstrapper.get<IConfigureTrackingData>(ConfigTypes.TrackingDataConfigurator).Configure();
bootstrapper.get<IConfigureGeneralApplicationData>(ConfigTypes.GeneralApplicationDataConfigurator).Configure();
