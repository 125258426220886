import { IAmJobFound, IAmJobSeniorityLevel, IAmJobFoundWage, IAmJobWageTimeUnit, IAmJobSearchJobCategory, IAmJobSearchLanguage, ISearchForJobs, IAmJobSource, IAmJobOrdersSorting } from "./abstraction";
import { inject, injectable } from "inversify";
import * as config from "../../config";
import { OpenAPI, SearchJobOfferCategory, SearchJobOfferLanguage, SearchJobOfferSeniorityLevel, SearchJobOfferSource, SearchJobOffersService, SearchJobOffersSortingOption } from "@all-it/api.joboffers.client";
import Session from 'supertokens-auth-react/recipe/session';
import { convertEnumGeneric } from "../../extensions/enumsExtensions";
import { PagedResult } from "../PagedResult";

@injectable()
export class SearchJobsService implements ISearchForJobs {
    @inject(config.Types.JobsApiSettings)
    private readonly _searchApiSettings!: config.IKeepJobsApiData;

    async search(
        searchTextTags: string[], 
        language: IAmJobSearchLanguage, 
        currency: string,
        locations: string[],
        remote: boolean,
        seniorityLevels: IAmJobSeniorityLevel[],
        wageFrom: number,
        wageTo: number,
        includeOffersWithoutSalaries: boolean,
        sources: IAmJobSource[],
        naturalPageNumber: number,
        pageSize: number,
        orderBy: IAmJobOrdersSorting,
        technologies: string[]
        ) : Promise<PagedResult<IAmJobFound>> {

        OpenAPI.BASE = this._searchApiSettings.jobsOfferApiUrl;
        
        if (await Session.doesSessionExist()) {
            const jwt = await Session.getAccessToken();

            OpenAPI.TOKEN = jwt;
        }

        const lang = convertEnumGeneric(language, SearchJobOfferLanguage);
        const seniorities = seniorityLevels.map(f => convertEnumGeneric(f, SearchJobOfferSeniorityLevel)!);
        const jobSources = sources.map(f => convertEnumGeneric(f, SearchJobOfferSource)!);
        const orderByConverted = convertEnumGeneric(orderBy, SearchJobOffersSortingOption)!;

        const jobsFound = await SearchJobOffersService
            .searchJobOffersSearch(
                lang, 
                [], 
                currency,
                wageTo, 
                wageFrom, 
                includeOffersWithoutSalaries,
                seniorities, 
                locations, 
                remote,
                jobSources, 
                searchTextTags,
                orderByConverted,
                naturalPageNumber - 1,
                pageSize,
                technologies);

        const jobsFoundConverted = jobsFound.items!.map(searchResult => {
            const retVal: IAmJobFound = {
                companyName: searchResult.companyName!,
                companyLogoUrl: searchResult.companyLogoUrl!,
                hashCode: searchResult.hashCode!,
                positionName: searchResult.positionName!,
                seniorityLevels: searchResult.seniorityLevels?.map(seniorityLevel => {
                    const seniority =  convertEnumGeneric(seniorityLevel, IAmJobSeniorityLevel)!;
                    return seniority;
                }) || [],
                wages: (searchResult.wageDetails || [])
                    .filter(wageDetail => wageDetail.wageRateMonthly !== undefined)
                    .filter(wageDetail => wageDetail.wageRateMonthly?.from !== 0 || wageDetail.wageRateMonthly?.to !== 0)
                    .map(wageDetail => {
                        if (wageDetail.timeUnit === undefined) {
                            throw new Error("wageDetail.timeUnit can't be undefined but it looks it is!"); //todo: resolve later
                        }
                        
                        if (wageDetail.wageRate === undefined) {
                            throw new Error("wageDetail.wageRate can't be undefined but it looks it is!"); //todo: resolve later
                        }

                        const timeUnit = convertEnumGeneric(wageDetail.timeUnit, IAmJobWageTimeUnit)!;
                        
                        const retVal: IAmJobFoundWage = {
                            timeUnit: timeUnit,
                            wageRate: {
                                currency: wageDetail.wageRate.currency!,
                                from: wageDetail.wageRate.from!,
                                to: wageDetail.wageRate.to!
                            },
                            wageRateMonthly: {
                                currency: wageDetail.wageRateMonthly!.currency!,
                                from: wageDetail.wageRateMonthly!.from!,
                                to: wageDetail.wageRateMonthly!.to!
                            }
                        };

                        return retVal;
                    }),
                dateAdded: new Date(searchResult.dateAdded!.toString()),
                locations: searchResult.locations || [],
                sources: searchResult.sources?.map(source => {
                    const retVal = convertEnumGeneric(source, IAmJobSource)!;
                    return retVal;
                }) || [],
                searchTextTags: searchTextTags || []
            };

            return retVal;
        });       
        
        const retVal: PagedResult<IAmJobFound> = {
            items: jobsFoundConverted,
            pageNumber: naturalPageNumber,
            pageSize: jobsFound.pageSize!,
            totalItemsCount: jobsFound.totalCount!,
            totalPagesCount: Math.ceil(jobsFound.totalCount! / jobsFound.pageSize!)
        };

        return retVal;
    }
}