let Types = {
    CacheService: Symbol("CacheService")
};

export { Types };

export interface ICacheData {
    get<T>(key: string): T | null;

    put<T>(key: string, value: T | null, ttl: number) : void;

    remove(key: string) : void;
}