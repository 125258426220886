import React, {Component} from 'react';
import Header from '../../Layout/Header';
import Footer from '../../Layout/Footer';
import * as User from '../../../services/User';
import { resolve } from 'inversify-react';
import { Guid } from 'typescript-guid';
import { Button } from 'react-bootstrap';
import Moment from 'react-moment';

import { batch } from '../../../extensions/arrayExtensions';
import { PaginationLocal } from '../../Element/Pagination';
import Select from 'react-select';
import { YesNoModalComponent } from '../../Element/YesNoModal';

import { Editor as WysiwygEditor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { convertToRaw, EditorState, ContentState, convertFromHTML, CompositeDecorator, ContentBlock } from 'draft-js';

import { toast } from 'react-toastify';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import 'react-tabs/style/react-tabs.css';

import DocViewer, { DocViewerRenderers, IDocument } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";

import './AppliedJobs.css';
import SearchTextTags from '../../Element/SearchTextTags';
import { generateSearchWordsRegex, highlightText, MarkComponent } from '../../../extensions/highlightText';

const maxItemsPerPage = 20; //todo: fudged! this should be taken from some config, not hardcoded!

enum SortByField {
	DateAddedFromTheNewest = "dateAddedFromTheNewest",
	DateAddedFromTheOldest = "dateAddedFromTheOldest",
	CompanyNameAscending = "companyNameAscending",
	CompanyNameDescending = "companyNameDescending",
	PositionNameAscending = "positionNameAscending",
	PositionNameDescending = "positionNameDescending",
}

interface IAppliedJobsState {
	id: Guid;
    jobOfferId: number;
    positionName: string;
    companyName: string;
    companyLogoUrl: string;
    snapshotId: number;
    snapshotUrl: string;
    dateApplied: Date;
	isCvAttached: boolean;
	comment: string;
	editorState: EditorState;
	cvViewerState: IDocument | null;
	pendingChanges: boolean;
	pendingCvToUpload: File | null;
}

interface IKeepTheState {
	currentModel: IAppliedJobsState[];
	allModelData: IAppliedJobsState[];
	allOrderByFields: { label: string; value: string; }[];
	currentOrderBy: { label: string; value: string };
	showConfirmAppRemovalModal: boolean;
	appliedJobSelectedToRemove: IAppliedJobsState;
	searchTextTags: string[];
}

class AppliedJobs extends Component<any, IKeepTheState>{
	@resolve(User.Types.AppliedJobsRetriever)
	private readonly _appliedJobsRetriever!: User.IRetrieveAppliedJobs;

	@resolve(User.Types.AppliedJobsRemover)
	private readonly _appliedJobsRemover!: User.IRemoveAppliedJob;

	@resolve(User.Types.AppliedResumeFilesRetriever)
	private readonly _appliedResumeFilesRetriever!: User.IRetrieveAppliedResumeFiles;

	@resolve(User.Types.AppliedResumeFileRemover)
	private readonly _appliedResumeFilesRemover!: User.IRemoveAppliedResumeFile;

	@resolve(User.Types.AppliedResumeFileSaver)
	private readonly _appliedResumeFilesSaver!: User.ISaveAppliedResumeFile;

	@resolve(User.Types.AppliedJobCommenter)
	private readonly _appliedJobCommenter!: User.ICommentAppliedJob;

	constructor(props: any) {
		super(props);

		const firstSelectedOrderByField = {label: "od najnowszego", value: SortByField.DateAddedFromTheNewest};

		this.state = {
			currentModel: [] as IAppliedJobsState[],
			allModelData: [] as IAppliedJobsState[],
			allOrderByFields: [
				firstSelectedOrderByField,
				{label: "od najstarszego", value: SortByField.DateAddedFromTheOldest},
				{label: "nazwa firmy rosnąco", value: SortByField.CompanyNameAscending},
				{label: "nazwa firmy malejąco", value: SortByField.CompanyNameDescending},
				{label: "nazwa stanowiska rosnąco", value: SortByField.PositionNameAscending},
				{label: "nazwa stanowiska malejąco", value: SortByField.PositionNameDescending},
			] as { label: string; value: string; }[],
			currentOrderBy: firstSelectedOrderByField,
			showConfirmAppRemovalModal: false,
			appliedJobSelectedToRemove: {} as IAppliedJobsState,
			searchTextTags: [],
		}

		this.onChangePage = this.onChangePage.bind(this);
		this.sortJobApplications = this.sortJobApplications.bind(this);
		this.onOrderByFieldChange = this.onOrderByFieldChange.bind(this);
		this.showConfirmAppRemovalModal = this.showConfirmAppRemovalModal.bind(this);
		this.handleConfirmAppRemovalNo = this.handleConfirmAppRemovalNo.bind(this);
		this.handleConfirmAppRemovalYes = this.handleConfirmAppRemovalYes.bind(this);
		this.hideConfirmAppRemovalModal = this.hideConfirmAppRemovalModal.bind(this);
		this.rebuildModelData = this.rebuildModelData.bind(this);
		this.onShowResume = this.onShowResume.bind(this);
		this.retrieveHistory = this.retrieveHistory.bind(this);
		this.setStateSynchronous = this.setStateSynchronous.bind(this);
		this.handleCvViewerTabChange = this.handleCvViewerTabChange.bind(this);
		this.handleCommentChange = this.handleCommentChange.bind(this);
		this.getEditorStateHtmlContent = this.getEditorStateHtmlContent.bind(this);
		this.handleCvChange = this.handleCvChange.bind(this);
		this.handleSaveChanges = this.handleSaveChanges.bind(this);
		this.filterModelData = this.filterModelData.bind(this);
	}

	async componentDidMount(): Promise<void> {
		await this.retrieveHistory();
	}

	saveCommentToAPI = async (applicationId: Guid, editorState: EditorState) => {
		const htmlContent = this.getEditorStateHtmlContent(editorState);
	
		await this._appliedJobCommenter.comment(applicationId, htmlContent);

		toast.success("Komentarz został zapisany");
	};

	getEditorStateHtmlContent = (editorState: EditorState) => {
		const rawContentState = convertToRaw(editorState.getCurrentContent());
		const markup = draftToHtml(rawContentState);
		return markup;
	}

	async retrieveHistory(): Promise<void>{
		const appliedJobs = await this._appliedJobsRetriever.retrieve();
		const attachedResumes = await this._appliedResumeFilesRetriever.retrieve();

		const mappedAppliedJobs = appliedJobs.map((item) => {
			const blocksFromHTML = convertFromHTML(item.comment || '');
			const editorState = EditorState.createWithContent(ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap));
			const commentAsHtml = this.getEditorStateHtmlContent(editorState);

			return {
				id: item.id,
				jobOfferId: item.jobOfferId,
				positionName: item.positionName,
				companyName: item.companyName,
				companyLogoUrl: item.companyLogoUrl,
				snapshotId: item.snapshotId,
				snapshotUrl: item.snapshotUrl,
				dateApplied: item.dateApplied,
				isCvAttached: attachedResumes.find((x) => x.applicationId.equals(item.id)) !== undefined,
				comment: commentAsHtml,
				editorState: editorState,
				cvViewerState: {} as IDocument,
				pendingChanges: false,
				pendingCvToUpload: null,
			} as IAppliedJobsState;
		});

		const filteredModelData = this.filterModelData(mappedAppliedJobs, this.state.searchTextTags);

		this.rebuildModelData(filteredModelData, this.state.currentOrderBy.value);
	}

	rebuildModelData(allModelData: IAppliedJobsState[], currentOrderBy: string) {
		const sorted = this.sortJobApplications(allModelData, currentOrderBy);
		const batchedModelData = batch(sorted, maxItemsPerPage); //todo: fudged! this should be taken from settings not hardcoded!
		const currentModel = batchedModelData?.[0] || [];

		this.setState({allModelData: sorted, currentModel: currentModel});
	}

	filterModelData(allModelData: IAppliedJobsState[], searchTextTags: string[]): IAppliedJobsState[] {
		if (searchTextTags.length === 0) {
			return allModelData;
		}

		const searchTextTagsLower = searchTextTags.map(x => x.toLowerCase());
		const searchRegex = generateSearchWordsRegex(searchTextTagsLower)!;

		return allModelData.filter(x => {
			const positionNameLower = x.positionName.toLowerCase();
			const companyNameLower = x.companyName.toLowerCase();
			const commentLower = x.comment.toLowerCase();

			return searchRegex.test(positionNameLower) 
			|| searchRegex.test(companyNameLower) 
			|| searchRegex.test(commentLower);
		});
	}

	onOrderByFieldChange(orderByField: {label: string, value: string} | null) {
		this.setState({currentOrderBy: orderByField!});
		this.rebuildModelData(this.state.allModelData, orderByField!.value);
		const currentModel = this.state.currentModel;
		this.onChangePage(currentModel);
	}

	onChangePage(pageOfItems: IAppliedJobsState[]) {
		this.setState({currentModel: pageOfItems});
	}

	async onShowResume(applicationId: Guid): Promise<void> {
		const file = await this._appliedResumeFilesRetriever.downloadResume(applicationId);
		const fileUrl = URL.createObjectURL(file);
		window.open(fileUrl);
	}

	showConfirmAppRemovalModal(appliedJobItem: IAppliedJobsState) {
		this.setState({showConfirmAppRemovalModal: true, appliedJobSelectedToRemove: appliedJobItem});
	}

	hideConfirmAppRemovalModal() {
		this.setState({showConfirmAppRemovalModal: false, appliedJobSelectedToRemove: {} as IAppliedJobsState});
	}

	handleConfirmAppRemovalNo() {
		this.hideConfirmAppRemovalModal();
	}

	async handleConfirmAppRemovalYes() {
		this.hideConfirmAppRemovalModal();

		const applicationId = this.state.appliedJobSelectedToRemove.id;
		if (this.state.appliedJobSelectedToRemove.isCvAttached)
		{
			await this._appliedResumeFilesRemover.remove(applicationId);
		}

		await this._appliedJobsRemover.remove(applicationId);
		await this.retrieveHistory();
	};

	sortJobApplications(incomingJobApplications: IAppliedJobsState[], sortBy: string): IAppliedJobsState[] {
		return [...incomingJobApplications].sort((a, b) => {
			switch (sortBy) {
				case SortByField.DateAddedFromTheNewest:
					return b.dateApplied.getTime() - a.dateApplied.getTime();
				case SortByField.DateAddedFromTheOldest:
					return a.dateApplied.getTime() - b.dateApplied.getTime();
				case SortByField.CompanyNameAscending:
					return a.companyName.localeCompare(b.companyName);
				case SortByField.CompanyNameDescending:
					return b.companyName.localeCompare(a.companyName);
				case SortByField.PositionNameAscending:
					return a.positionName.localeCompare(b.positionName);
				case SortByField.PositionNameDescending:
					return b.positionName.localeCompare(a.positionName);
				default:
					return 0;
			}
		});
	}

	handleSaveChanges = async (application: IAppliedJobsState) => {
		const htmlContent = this.getEditorStateHtmlContent(application.editorState);
	
		await this._appliedJobCommenter.comment(application.id, htmlContent);

		if (application.pendingCvToUpload) {
			await this._appliedResumeFilesSaver.save(application.id, application.pendingCvToUpload);
			application.cvViewerState = { uri: URL.createObjectURL(application.pendingCvToUpload!), fileName: application.pendingCvToUpload!.name };
			application.isCvAttached = true;
		}
		
		application.pendingChanges = false;
		application.pendingCvToUpload = null;

		this.setState(this.state);
		
		toast.success("Zmiany zostały zapisane");
	}

	handleCvViewerTabChange = async (tabKeyName: string, application: IAppliedJobsState) => {
		if (tabKeyName !== 'cv') {
			application.cvViewerState = null;
		}
		else {
			try {
				const docs = await this._appliedResumeFilesRetriever.downloadResume(application.id);
				application.cvViewerState = { uri: URL.createObjectURL(docs), fileName: docs.name };
			}
			catch(e) {
				//intentioanlly left blank
			}
		}

		this.setState(this.state);
	}

	handleCommentChange = (editorState: EditorState, application: IAppliedJobsState) => {
		application.editorState = editorState;

		const htmlContent = this.getEditorStateHtmlContent(editorState);
		if (htmlContent !== application.comment) {
			application.pendingChanges = true;
			application.comment = htmlContent;
		}

		this.setState(this.state);
	}

	handleCvChange = (e: React.ChangeEvent<HTMLInputElement>, application: IAppliedJobsState) => {
		application.pendingChanges = true;
		application.pendingCvToUpload = e.target.files![0];
		this.setState(this.state);
	}

	setStateSynchronous(stateUpdate: {}) : Promise<void> {
        return new Promise(resolve => {
            this.setState(stateUpdate, () => resolve());
        });
    }

	render(){
		const currentModel = this.state.currentModel;
		const isInInitialState = this.state.allModelData == null;

		return(
			<div className="page-wraper">
				<Header />
					<div className="page-content bg-white">
						
						<div className="content-block">
							
							<div className="section-full bg-white p-t50 p-b20">
								<div className="container">
									<h5 className="text-center">Wysłane aplikacje</h5>
									<hr />
									{
										<>
											<div className='row' id='searchTextTags' style={{minHeight: 50}}>
													<SearchTextTags 
														onTypingFinished={async (searchTextTags) => { 
															await this.setStateSynchronous({searchTextTags: searchTextTags});
															await this.retrieveHistory();
															}}

														onSearchTextTagsChange={async (searchTextTags) => {
															await this.setStateSynchronous({searchTextTags: searchTextTags});
														}}

														searchTextTags={this.state.searchTextTags}
													/>
											</div>
											<div className="row">
												<div className="col-xl-12 col-lg-12">
													<div className='float-right'>
														<button type="submit" className="site-button" style={{zIndex:0}} onClick={this.retrieveHistory}>Szukaj</button>
													</div>
												</div>
											</div>
											<div className="row justify-content-center">
												<div className="col-xl-12 col-lg-12 m-b30 browse-job">
													<div className="job-bx-title clearfix" style={{borderBottom: 'none'}}>
														<h6 className="font-weight-700 pull-left">Liczba wysłanych aplikacji: {this.state.allModelData.length}</h6>
														<div className="float-right">
															Sortuj:
															<Select 
																options={this.state.allOrderByFields} 
																closeMenuOnSelect={true}
																onChange={this.onOrderByFieldChange} 
																placeholder="Sortowanie po: " value={ this.state.currentOrderBy } />
														</div>
													</div>
													<ul className="post-job-bx browse-job">
														{this.state.currentModel.map((application, index)=>(
															<li key={application.id.toString()}>
																<div className="post-bx">
																	<div className="container">	
																		<div className="row">
																			<div className="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
																				<img src={application.companyLogoUrl} alt={application.companyName?.trim()} />
																				<small>
																					<h6 style={{fontSize: '10px', lineHeight: 'inherit'}} dangerouslySetInnerHTML={{__html: highlightText(application.companyName, this.state.searchTextTags)}}></h6>
																				</small>
																			</div>
																			<div className="col-9 col-sm-9 col-md-10 col-lg-10 col-xl-10 p-r0">
																				<span className="float-left">
																					<h4 dangerouslySetInnerHTML={{__html: highlightText(application.positionName, this.state.searchTextTags)}}></h4>
																				</span>
																				&nbsp;
																				<span className="float-right">
																					<small>
																						<span className="text-black">Aplikowano:&nbsp;</span> 
																						<span title={application.dateApplied.toLocaleString()}>
																							<Moment locale="pl" fromNow >{application.dateApplied}</Moment>&nbsp;
																						</span>

																						<Button variant="warning" className='btn-sm' onClick={() => this.showConfirmAppRemovalModal(application)}>Usuń</Button>
																					</small>
																				</span>
																			</div>
																		</div>
																		<div className="row m-l10 m-t10">
																			<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
																				<Tabs defaultActiveKey="notes" className='mb-3 handCursor' onSelect={(t) => this.handleCvViewerTabChange(t!, application)}>
																					<Tab title="Notatki" eventKey="notes">
																						<WysiwygEditor 
																							customDecorators={[
																								{
																									strategy: (contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState) => {
																										const text = contentBlock.getText();

																										if (!text || this.state.searchTextTags.length === 0) {
																											return;
																										}

																										const regex = generateSearchWordsRegex(this.state.searchTextTags)!;

																										const matches = text.matchAll(regex) || [];
																										for (const match of matches) {
																											if (match.index !== undefined && match[0].length !== undefined) {
																												callback(match.index, match.index + match[0].length);
																											}
																										}
																									},
																									component: (props: any) => {
																										return <MarkComponent {...props} />;
																									}
																								}
																							]}
																							editorClassName="wysiwygEditor" 
																							editorState={application.editorState}
																							onEditorStateChange={(editorState) => this.handleCommentChange(editorState, application)}
																						/>
																					</Tab>
																					<Tab title="Oferta" eventKey="offer">
																						<a href={application.snapshotUrl} target="_blank" rel="noopener noreferrer" title='Kliknij aby zobaczyć w pełnym wymiarze'>
																							<img src={application.snapshotUrl} alt={application.positionName} />
																						</a>
																					</Tab>
																					<Tab title="CV" eventKey="cv" >
																						{
																							application.isCvAttached && <DocViewer documents={[application.cvViewerState!]} pluginRenderers={DocViewerRenderers} />
																						}
																						
																						{
																							application.isCvAttached && <div className='text-center'>Dokument się nie wyświetla? <a href='#' onClick={(e) => { e.preventDefault(); this.onShowResume(application.id);}}>Kliknij tutaj aby zobaczyć w nowym oknie</a></div>
																						}

																						{
																							!application.isCvAttached && <div className='text-center'>Brak załączonego CV</div>
																						}

																						<div className='text-center'>
																							Wgrać nowy plik? <input type='file' title='' onChange={(e) => this.handleCvChange(e, application)} />
																						</div>
																					</Tab>
																				</Tabs>
																			</div>
																			<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right">
																				{
																					application.pendingChanges && <Button className='btn btn-sm btn-primary' onClick={() => this.handleSaveChanges(application)}>Zapisz zmiany</Button>
																				}
																				{
																					!application.pendingChanges && <a href='#' className='btn btn-sm btn-secondary disabled'>Zapisz zmiany</a>  
																				} 
																			</div>
																		</div>
																	</div>
																</div>
															</li>
														))}	
														
													</ul>
													<div className="d-flex justify-content-center">
														<PaginationLocal items={this.state.allModelData!} onChangePage={this.onChangePage} initialPage={1} pageSize={maxItemsPerPage} /> 
													</div>
												</div>
											</div>
											{
												!isInInitialState &&
												!currentModel.length &&
												<div className="row">
													<div className="col-xl-12 col-lg-12 col-sm-12 col-md-12 col-xs-12 col-12">
														<div className="d-flex justify-content-center">
															<h3>Brak wyników</h3> { /* todo: fudged! */  }
														</div>
													</div>
												</div>
											}
										</>
									}
									
								</div>
							</div>
							
						</div>
					</div>

					<YesNoModalComponent 
						body={`Czy na pewno chcesz usunąć aplikację '${this.state.appliedJobSelectedToRemove.positionName} (${this.state.appliedJobSelectedToRemove.companyName})'?`} title='Usuń aplikację' 
						show={this.state.showConfirmAppRemovalModal} onYes={this.handleConfirmAppRemovalYes} onNo={this.handleConfirmAppRemovalNo}
						noButtonText='Nie' yesButtonText='Tak, usuń tą aplikację' />
				
				<Footer />	
			</div>
		)
	}
}
export default AppliedJobs; 