import { is } from 'immutable';
import React, { useRef, useState } from 'react';
import { GroupBase } from 'react-select';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';

// Define a reusable wrapper component
const ZIndexCreatableSelect = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: CreatableProps<Option, IsMulti, Group>
): JSX.Element => {
  const [isFocused, setIsFocused] = useState(false);
  
  const handleFocus = (e: React.FocusEvent<HTMLInputElement, HTMLElement>) => {
    setIsFocused(true);
    if (props.onFocus) props.onFocus(e); // Call user-defined onFocus
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, HTMLElement>) => {
    setIsFocused(false);
    if (props.onBlur) props.onBlur(e); // Call user-defined onBlur
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      zIndex: isFocused ? 1000 : 'auto',
      overflow: isFocused ? 'visible' : 'hidden', // Allow expansion when focused
      flexWrap: isFocused ? 'wrap' : 'nowrap',   // Prevent height expansion when not focused
      transition: 'all 0.2s ease',              // Smooth transition
      height: isFocused ? 'auto' : 0,            // Hide menu when not focused
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: isFocused ? 1000 : 'auto',
      overflow: isFocused ? 'visible' : 'hidden', // Allow expansion when focused
      flexWrap: isFocused ? 'wrap' : 'nowrap',   // Prevent height expansion when not focused
      transition: 'all 0.2s ease',  
      height: isFocused ? 'auto' : 0,            // Hide menu when not focused
    }),
    ...props.styles, // Merge user-defined styles
  };

  return (
    <CreatableSelect
      {...props} // Pass all props to the base component
      onFocus={handleFocus}
      onBlur={handleBlur}
      styles={customStyles} // Apply combined styles
    />
  );
};

export default ZIndexCreatableSelect;
